import { CustomRange } from "@components/CustomRange";
import { AddTipProps } from "@components/donate/DonateV3/PaymentProcess/components/AddTip/types";
import Big from "big.js";
import React, { useEffect, useMemo, useState } from "react";

import { colorPalette } from "@every.org/common/src/display/palette";
import { TIP_SLIDER_DEFAULT_SETTINGS } from "@every.org/common/src/entity/constants";

export function getInitialSliderSettingsForStocks(amount: number) {
  const isDecimalAmount = !Number.isInteger(amount);
  // use percentage slider if amount is decimal
  if (isDecimalAmount) {
    return TIP_SLIDER_DEFAULT_SETTINGS;
  }

  const sliderOptions = {
    min: 0,
    max: 0,
    step: 1,
    initial: 0,
    customAmountLink: true,
  };

  if (amount >= 47) {
    // Set max to 30% of shares donated, rounded down
    const step = Math.round((amount * 0.3) / 13);
    sliderOptions.max = step * 13;
    sliderOptions.step = step;
    // Default tip amount should not be above halfway
    sliderOptions.initial = Math.floor(13 / 2) * sliderOptions.step;
  } else {
    // Custom slider for donations less than 47 shares
    const sliderSteps = Math.floor(amount / 3);
    sliderOptions.max = Math.max(sliderSteps, 3);
    sliderOptions.step = 1;
    sliderOptions.initial = Math.floor(sliderSteps / 2);
  }

  return sliderOptions;
}

export const StockTipSlider = (props: AddTipProps) => {
  const { formContext, form, onChange } = props;
  const stockAmount = form.watch("stockAmount");

  const isDecimalAmount =
    typeof stockAmount === "number" && !Number.isInteger(stockAmount);

  const sliderSettings = useMemo(
    () => getInitialSliderSettingsForStocks(stockAmount ?? 0),
    [stockAmount]
  );

  const [sliderValue, setSliderValue] = useState(sliderSettings.initial);

  const computedValue = useMemo(
    () =>
      isDecimalAmount
        ? new Big(stockAmount).div(100).mul(sliderValue)
        : sliderValue,
    [stockAmount, sliderValue, isDecimalAmount]
  );

  useEffect(() => {
    onChange(computedValue.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computedValue]);

  return (
    <CustomRange
      themeColor={
        !sliderValue ? colorPalette.grayMedium : formContext.themeColor
      }
      {...sliderSettings}
      value={sliderValue}
      onChange={setSliderValue}
      popoverContent={
        isDecimalAmount ? (
          `${sliderValue}%`
        ) : (
          <span>{sliderValue}&nbsp;shares</span>
        )
      }
    />
  );
};
