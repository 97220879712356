import {
  ALTERNATE_PAYMENT_FLOW_OPTIONS,
  DonateModalAction,
} from "@components/donate/DonateV3/types";
import { Big } from "big.js";
import { useMemo } from "react";

import { GivingCreditResponse } from "@every.org/common/src/codecs/entities";
import { GIFT_CARDS_NONPROFIT_ID } from "@every.org/common/src/entity/constants";
import { DonationFlowPaymentOption } from "@every.org/common/src/entity/types";
import { disablePaymentMethodsSpec } from "@every.org/common/src/entity/types/configVariable";

import {
  useConfigVariable,
  UseConfigVariableStatus,
} from "src/context/ConfigVariableContext";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { paymentFlowOptionsFromString } from "src/utility/helpers";
import { isWebview } from "src/utility/isWebview";
import { logger } from "src/utility/logger";

const DISABLED_OPTIONS_FOR_UPDATE_DONATION = [
  ...ALTERNATE_PAYMENT_FLOW_OPTIONS,
  DonationFlowPaymentOption.PAYMENT_REQUEST,
  DonationFlowPaymentOption.PAYPAL,
  DonationFlowPaymentOption.VENMO,
];

const DISABLED_OPTIONS_FOR_GIFT_CARDS = [
  ...ALTERNATE_PAYMENT_FLOW_OPTIONS,
  DonationFlowPaymentOption.PAYPAL,
  DonationFlowPaymentOption.VENMO,
  DonationFlowPaymentOption.GIFT_CARD,
];

const ONE_TIME_PAYMENT_FLOW_OPTIONS = [
  DonationFlowPaymentOption.CRYPTO,
  DonationFlowPaymentOption.DAF,
  DonationFlowPaymentOption.STOCKS,
  DonationFlowPaymentOption.VENMO,
  DonationFlowPaymentOption.GIFT_CARD,
];

function addToSet<T>(set: Set<T>, values: Array<T> | Set<T>) {
  values.forEach((elem) => set.add(elem));
}

function deleteFromSet<T>(set: Set<T>, values: Array<T> | Set<T>) {
  values.forEach((elem) => set.delete(elem));
}

export const usePaymentFlowOptions = ({
  nonprofit,
  paymentMethodsFromUrl,
  successUrl,
  donateAction,
  lockMonthlyFrequency,
  availableGivingCredit,
  inaccessibleGivingCredit,
  givingCreditToRedeem,
}: {
  nonprofit: ContextNonprofit;
  paymentMethodsFromUrl?: string;
  successUrl?: string;
  donateAction: DonateModalAction;
  lockMonthlyFrequency: boolean;
  availableGivingCredit?: Big;
  inaccessibleGivingCredit?: Big;
  givingCreditToRedeem?: GivingCreditResponse;
}) => {
  const disabledPaymentMethods = useConfigVariable(disablePaymentMethodsSpec);

  const paymentFlowOptions = useMemo(() => {
    const paymentOptions = new Set<DonationFlowPaymentOption>([]);

    if (paymentMethodsFromUrl) {
      addToSet(
        paymentOptions,
        paymentFlowOptionsFromString(paymentMethodsFromUrl)
      );
    } else {
      addToSet(
        paymentOptions,
        new Set(Object.values(DonationFlowPaymentOption))
      );
      // Only delete methods if they have not been explicitly set
      if (lockMonthlyFrequency) {
        deleteFromSet(paymentOptions, ONE_TIME_PAYMENT_FLOW_OPTIONS);
      }
    }

    if (disabledPaymentMethods.status === UseConfigVariableStatus.SUCCESS) {
      deleteFromSet(
        paymentOptions,
        paymentFlowOptionsFromString(disabledPaymentMethods.value)
      );
    }

    if (donateAction === DonateModalAction.UPDATE) {
      deleteFromSet(paymentOptions, DISABLED_OPTIONS_FOR_UPDATE_DONATION);
    }

    if (nonprofit.id === GIFT_CARDS_NONPROFIT_ID) {
      deleteFromSet(paymentOptions, DISABLED_OPTIONS_FOR_GIFT_CARDS);
    }

    if (nonprofit.metadata?.disabledPaymentFlowOptions) {
      deleteFromSet(
        paymentOptions,
        nonprofit.metadata.disabledPaymentFlowOptions
      );
    }

    if (isWebview()) {
      deleteFromSet(paymentOptions, [DonationFlowPaymentOption.VENMO]);
    }

    if (paymentOptions.size < 1) {
      logger.warn({
        message:
          "Donation flow has no valid payment options. Returning all options as valid...",
        data: { paymentOptions, href: location.href },
      });
    }

    if (
      (availableGivingCredit?.gt(0) || inaccessibleGivingCredit?.gt(0)) &&
      !givingCreditToRedeem
    ) {
      deleteFromSet(paymentOptions, [DonationFlowPaymentOption.GIFT_CARD]);
    }

    return paymentOptions;
  }, [
    paymentMethodsFromUrl,
    disabledPaymentMethods,
    donateAction,
    nonprofit.id,
    nonprofit.metadata?.disabledPaymentFlowOptions,
    lockMonthlyFrequency,
    availableGivingCredit,
    inaccessibleGivingCredit,
    givingCreditToRedeem,
  ]);

  return paymentFlowOptions;
};
