import React from "react";

import { colorCssVars } from "src/theme/color";
import { verticalStackCss } from "src/theme/spacing";
import { FontWeight, textSizeCss } from "src/theme/text";
import { useStatSigLayer } from "src/utility/abtesting";

const TipDescriptionCopyExperiment = () => {
  const description = useStatSigLayer("tip_copy_layer").get("description", "");
  if (description) {
    return <p>{description}</p>;
  }
  return null;
};

const TipTitleCopyExperiment = () => {
  const title = useStatSigLayer("tip_copy_layer").get(
    "title",
    "Choose a donation for Every.org"
  );
  return <React.Fragment>{title}</React.Fragment>;
};

export const AddTipHeader = () => {
  return (
    <div css={verticalStackCss.xs}>
      <h4 css={textSizeCss.s}>
        <TipTitleCopyExperiment />{" "}
        <span
          css={{
            color: `var(${colorCssVars.text.secondary})`,
            fontWeight: FontWeight.REGULAR,
          }}
        >
          (optional)
        </span>
      </h4>
      <TipDescriptionCopyExperiment />
    </div>
  );
};
