import { CreateOrUpdateDonationResult } from "@components/donate/DonateV3/types";
import dynamic from "next/dynamic";
import React from "react";

import {
  PersonalDonationChargeResponse,
  PersonalDonationResponse,
} from "@every.org/common/src/codecs/entities";
import { DonationFrequency } from "@every.org/common/src/entity/types";
import {
  capturePaypalOrderRouteSpec,
  createPaypalOrderRouteSpec,
  createPaypalSubscriptionRouteSpec,
} from "@every.org/common/src/routes/donate";

import { queryApi } from "src/utility/apiClient";
import { logger } from "src/utility/logger";
import { isDevOrTest } from "src/utility/runtimeEnvironment";

const PAYMENT_CAPTURE_ERROR_MESSAGE =
  "Error processing PayPal payment. Please try again or use a different payment method.";

const paypalClientId =
  process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID ||
  process.env.REACT_APP_PAYPAL_CLIENT_ID;
const paypalSubscriptionPlanId =
  process.env.NEXT_PUBLIC_PAYPAL_SUBSCRIPTION_PLAN_ID ||
  process.env.REACT_APP_PAYPAL_SUBSCRIPTION_PLAN_ID;
if (!paypalClientId || !paypalSubscriptionPlanId) {
  (isDevOrTest ? logger.warn : logger.fatal)({
    message: "Missing Paypal environment variables, Paypal flow will not work",
  });
}
const PayPalButton = dynamic(() => import("./PayPalButton"));
const disableFunding = "card,credit";

function handleError({
  amount,
  cancelCallback,
  donationId,
  error,
  frequency,
  onApprove,
}: {
  amount: string;
  cancelCallback: ((errorMessage?: string) => void) | undefined;
  donationId?: PersonalDonationResponse["id"];
  error: unknown;
  frequency: DonationFrequency;
  onApprove: boolean;
}) {
  const errorMessage =
    typeof error === "string"
      ? error
      : error instanceof Error
      ? error.message
      : undefined;
  const message = onApprove
    ? "Error on PayPal processing approval"
    : frequency === DonationFrequency.ONCE
    ? "Error capturing PayPal payment"
    : "Error capturing PayPal subscription";
  const typedError = error instanceof Error ? error : undefined;
  logger.error({
    message,
    error: typedError,
    data: {
      origError: error,
      donationChargeId: donationId,
      amount,
      errorMessage,
      frequency,
    },
  });
  if (cancelCallback) {
    cancelCallback(error ? PAYMENT_CAPTURE_ERROR_MESSAGE : undefined);
  }
}

interface OurPaypalButtonProps {
  amount: string;
  frequency: DonationFrequency;
  handleSubmit: () => Promise<CreateOrUpdateDonationResult | undefined>;
  finishCallback?: () => void;
  cancelCallback?: (errorMessage?: string) => void;
  enableVenmo?: boolean;
}

export const OurPaypalButton: React.FCC<OurPaypalButtonProps> = ({
  amount,
  frequency,
  handleSubmit,
  finishCallback,
  cancelCallback,
  enableVenmo,
}) => {
  if (!paypalClientId || !paypalSubscriptionPlanId) {
    return null;
  }

  let donationId: PersonalDonationResponse["id"] | undefined;
  let donationChargeId: PersonalDonationChargeResponse["id"] | undefined;

  const submitDonation = async () => {
    const result = await handleSubmit();
    donationId = result?.donation.id;
    donationChargeId = result?.donationCharge?.id;
    return { donationId, donationChargeId };
  };

  // Build the button
  try {
    switch (frequency) {
      case DonationFrequency.ONCE: {
        return (
          <PayPalButton
            css={{ display: "flex", width: "100%" }}
            createOrder={async (data, actions) => {
              const { donationId, donationChargeId } = await submitDonation();
              if (!donationId || !donationChargeId) {
                throw new Error("Donation ID not set");
              }
              const { order } = await queryApi(createPaypalOrderRouteSpec, {
                routeTokens: {},
                queryParams: {},
                body: {
                  donationChargeId,
                },
              });

              if (!order.id) {
                throw new Error("PayPal order not created");
              }

              return order.id;
            }}
            onApprove={async (data, actions) => {
              if (!actions.order) {
                return Promise.resolve();
              }
              const { order } = await queryApi(capturePaypalOrderRouteSpec, {
                routeTokens: {},
                queryParams: {},
                body: {
                  orderId: data.orderID,
                },
              });

              const errorDetail = order?.details?.[0];
              if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                return actions.restart();
              } else if (errorDetail) {
                logger.error({
                  message: "Paypal order failed to capture",
                  data: {
                    details: errorDetail,
                    orderId: data.orderID,
                  },
                });
                return handleError({
                  amount,
                  cancelCallback,
                  donationId,
                  error: errorDetail.issue,
                  frequency,
                  onApprove: true,
                });
              }

              if (finishCallback) {
                return finishCallback();
              }
            }}
            onCancel={(data) => {
              if (cancelCallback) {
                cancelCallback();
              }
            }}
            onError={(error) =>
              handleError({
                amount,
                cancelCallback,
                donationId,
                error,
                frequency,
                onApprove: true,
              })
            }
            options={{
              clientId: paypalClientId,
              currency: "USD",
              disableFunding,
              ...(enableVenmo ? { enableFunding: "venmo" } : {}),
            }}
            {...(enableVenmo ? { fundingSource: "venmo" } : {})}
          />
        );
      }
      case DonationFrequency.MONTHLY: {
        return (
          <PayPalButton
            css={{ display: "flex", width: "100%" }}
            createSubscription={async (data: any, actions: any) => {
              const { donationId, donationChargeId } = await submitDonation();
              if (!donationId || !donationChargeId) {
                throw new Error("Donation ID not set");
              }
              const { subscription } = await queryApi(
                createPaypalSubscriptionRouteSpec,
                {
                  routeTokens: {},
                  queryParams: {},
                  body: {
                    donationChargeId,
                  },
                }
              );

              if (!subscription.id) {
                throw new Error("PayPal order not created");
              }

              return subscription.id;
            }}
            onApprove={() => {
              if (finishCallback) {
                finishCallback();
              }
              return Promise.resolve();
            }}
            onCancel={() => {
              if (cancelCallback) {
                cancelCallback();
              }
            }}
            onError={(error) => {
              handleError({
                amount,
                cancelCallback,
                donationId,
                error,
                frequency,
                onApprove: false,
              });
            }}
            options={{
              clientId: paypalClientId,
              vault: true,
              intent: "subscription",
              currency: "USD",
              disableFunding,
              ...(enableVenmo ? { enableFunding: "venmo" } : {}),
            }}
            {...(enableVenmo ? { fundingSource: "venmo" } : {})}
          />
        );
      }
    }
  } catch (error) {
    logger.error({ message: "PayPal button loading failed", error });
  }
  return null;
};
