export const CURRENCY_PRECISION = 32; // total number of digits
export const CURRENCY_SCALE = 18; // number of digits to the right of the decimal place

export const GIFT_CARDS_SLUG = "gift-cards";
export const GIFT_CARDS_NONPROFIT_ID = "273f8609-0557-4e4a-b5ae-cd70452d6a6f";

export const FUND_ACCOUNT_NONPROFIT_SLUG = "fund-account";
export const FUND_ACCOUNT_NONPROFIT_ID = "a81c40d5-6db4-4605-9cef-076fe7977fbc";

export const GIVING_MULTIPLIER_NONPROFIT_ID =
  "864cd781-ab4e-4f21-9c0a-0dbdf654a1ae";
export const FARM_KIND_NONPROFIT_ID = "ade2fbc7-143e-4e6b-9cc1-eb22e03426f0";
export const FARM_KIND_SLUG = "farmkind";
export const FARMKIND_MATCHING_USER_ID = "b6314f9c-4e9d-47d0-8e77-a7e1a1e2ac3d";

export const EDO_SLUG = "everydotorg";
export const EDO_NONPROFIT_ID = "413605e8-449a-45c6-a473-4949fbcf53c3";

export const EDO_MATCHING_SLUG = "matching";
export const EDO_MATCHING_NONPROFIT_ID = "40206469-4241-4102-b159-867f6be8ab1e";

export const GIVELIST_SLUG = "givelist";
export const GIVELIST_NONPROFIT_ID = "2c0c7a83-f5c2-4d1e-9ede-47d31a2bd83e";

export const MYRIAD_NONPROFIT_ID = "d0530836-4905-482e-bd16-c88b05091258";

export const UNDP_NONPROFIT_ID = "9194918b-f5d2-4d26-9359-7c9036535f54";

export const GIVEWELL_NONPROFIT_ID = "cf43c05f-4509-4672-9eb4-169bb4652701";
export const GIVEWELL_UNRESTRICTED_NONPROFIT_ID =
  "6e449aca-311a-4b8b-b888-956f1790f3a5";

export const IRC_NONPROFIT_ID = "cb3829b1-3fb9-42f1-b91a-f7211ac793a6";

export const GIVING_GAP_WEBHOOK_TOKEN = "XZZ8hrLYE4KJrMANPF8KMGd2";

export const OWID_NONPROFIT_ID = "50a1cb5f-c1ae-4aa8-a9ce-e98ea22ffacf";

export const SPECIAL_NONPROFIT_IDS = [
  GIFT_CARDS_NONPROFIT_ID,
  FUND_ACCOUNT_NONPROFIT_ID,
  GIVING_MULTIPLIER_NONPROFIT_ID,
  EDO_MATCHING_NONPROFIT_ID,
  FARM_KIND_NONPROFIT_ID,
];

export const DISALLOWED_FUNDRAISER_NONPROFIT_IDS = [
  GIFT_CARDS_NONPROFIT_ID,
  FUND_ACCOUNT_NONPROFIT_ID,
  EDO_MATCHING_NONPROFIT_ID,
];

export const NO_GLOBAL_MATCHING = SPECIAL_NONPROFIT_IDS;

export const NO_TIPPING = [
  EDO_NONPROFIT_ID,
  GIFT_CARDS_NONPROFIT_ID,
  FUND_ACCOUNT_NONPROFIT_ID,
];

export const EVERYDOTORG_NONPROFIT_IDS = [
  EDO_NONPROFIT_ID,
  GIFT_CARDS_NONPROFIT_ID,
  FUND_ACCOUNT_NONPROFIT_ID,
  EDO_MATCHING_NONPROFIT_ID,
];

export const PROFILE_IMG_PLACEHOLDER_ID = "default_cover_bxtuxo";
export const BUTTON_TYPE_URL_PARAM = "type";
export const CONFIGURE_BUTTON_SRC_URL_PARAM = "src";

export const MAX_API_KEYS_PER_USER = 3;
export const MAX_API_SECRETS_PER_USER = 3;

export const FGC_CAMPAIGN_CODE = "fall.giving.challenge.2022";

export const TIP_SLIDER_DEFAULT_SETTINGS = {
  min: 0,
  max: 30,
  step: 2.5,
  initial: 15,
};

export const TIP_BUTTONS_DEFAULT_VALUES = [10, 15, 20];

export const HOTJAR_FEEDBACK_EVENT_NAME = "hotjar_feedback_link";
export const HOTJAR_ABANDONED_DONATE_EVENT_NAME = "hotjar_abandoned_donate";
export const HOTJAR_NONPROFIT_ADMIN_FEEDBACK_EVENT_NAME =
  "hotjar_nonprofit_admin_feedback";

export const TED_COMMUNITY_SLUG = "ted";
export const MICROSOFT_COMMUNITY_SLUG = "msftalumni";
export const GIFT_CARD_COMMUNITY_SLUGS = [
  TED_COMMUNITY_SLUG,
  MICROSOFT_COMMUNITY_SLUG,
];

export const DIRECTORY_TAGS_ALLOW_SETS = new Map([
  [
    MYRIAD_NONPROFIT_ID,
    new Set([
      "agriculture",
      "climate",
      "conservation",
      "coronavirus",
      "culture",
      "disabilities",
      "education",
      "entrepreneurship",
      "environment",
      "food-security",
      "gender-equality",
      "health",
      "humans",
      "indigenous-peoples",
      "justice",
      "museums",
      "poverty",
      "refugees",
      "research",
      "science",
      "art",
      "water",
      "youth",
    ]),
  ],
]);

export const DOUBLE_DONATION_ERROR_NAME = "DoubleDonation";

export const STATSIG_PROXY_URL = "https://p.every.org/v1/";

export const SPECIAL_FUNDRAISER_NONPROFIT_SLUG = "special-fundraiser";
export const SPECIAL_FUNDRAISER_NONPROFIT_ID =
  "ddacc399-20c5-462e-80e1-97fa958071ea";
