import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import {
  DonateInstructions,
  DonateInstructionsType,
} from "@components/donate/DonateV3/PaymentProcess/components/DonateInstructions";
import { DonationSummary } from "@components/donate/DonateV3/PaymentProcess/components/DonationSummary";
import { GoBackButton } from "@components/donate/DonateV3/PaymentProcess/components/GoBackButton";
import {
  PageContainer,
  ProcessContainer,
} from "@components/donate/DonateV3/PaymentProcess/components/PageContainer";
import {
  PaymentProcessRouteName,
  paymentProcessRouteNameToPathMap,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import {
  StepperType,
  useStepper,
} from "@components/donate/DonateV3/PaymentProcess/useStepper";
import {
  CreateOrUpdateDonationResult,
  DonateFormType,
} from "@components/donate/DonateV3/types";
import { useCallback } from "react";
import { UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import {
  DonationFlowPaymentOption,
  DonationFrequency,
} from "@every.org/common/src/entity/types";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { verticalStackCss } from "src/theme/spacing";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";

function getInstructions({
  amount,
  frequency,
  nonprofit,
  nonce,
}: {
  amount: string;
  frequency: string;
  nonprofit: ContextNonprofit;
  nonce?: string;
}): DonateInstructionsType {
  return [
    [
      "Grant to",
      "Every.org (EIN: 61-1913297) 58 West Portal Ave #781 San Francisco, CA 94127",
    ],
    ["Grant amount", amount],
    nonce
      ? [
          "Reference code",
          nonce,
          "Please ask your provider to include the reference code in the grant",
        ]
      : undefined,
    ["Designation", `${nonprofit.name} (every.org/${nonprofit.primarySlug})`],
    ["Frequency", frequency],
    ["Contact info", "Karissa Sparks, COO"],
    ["Email", "team@every.org"],
    ["Phone number", "+1 (415) 650-0503"],
  ].filter((x) => x) as DonateInstructionsType;
}

interface ManualDAFInstructionsProps {
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
  nonprofit: ContextNonprofit;
  fundraiser?: FundraiserResponse | null;
  createOrUpdateDonationResult?: CreateOrUpdateDonationResult;
  handleConfirmedDonation: (result: CreateOrUpdateDonationResult) => boolean;
}

export function ManualDAFInstructions({
  form,
  formContext,
  nonprofit,
  fundraiser,
  createOrUpdateDonationResult,
  handleConfirmedDonation,
}: ManualDAFInstructionsProps) {
  const navigate = useNavigate();

  const handleDonationComplete = useCallback(() => {
    if (
      createOrUpdateDonationResult &&
      !handleConfirmedDonation(createOrUpdateDonationResult)
    ) {
      navigate(
        paymentProcessRouteNameToPathMap[PaymentProcessRouteName.THANK_YOU]
      );
    }
  }, [createOrUpdateDonationResult, handleConfirmedDonation, navigate]);

  const stepper = useStepper(StepperType.MANUAL_DAF, 2);

  const currency = form.watch("currency");
  const frequency = form.watch("frequency");
  const amountSting = displayCurrencyValueInUserLocale({
    currencyValue: {
      currency,
      amount: formContext.totalAmountBig,
    },
  });
  const frequencyString =
    frequency === DonationFrequency.ONCE ? "One-time" : "Recurring monthly";

  return (
    <PageContainer>
      <GoBackButton form={form} formContext={formContext} />
      <ProcessContainer css={verticalStackCss.l}>
        {stepper}
        <DonateInstructions
          instructions={getInstructions({
            amount: amountSting,
            frequency: frequencyString,
            nonprofit,
            nonce: formContext.nonce,
          })}
        />
        <DonationSummary
          form={form}
          formContext={formContext}
          fundraiser={fundraiser}
          paymentOption={DonationFlowPaymentOption.DAF}
          nonprofit={nonprofit}
        />
        <Button
          data-tname="donate-daf-instructions"
          css={{ alignSelf: "flex-end" }}
          role={ButtonRole.PRIMARY}
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: handleDonationComplete,
          }}
        >
          I will contact my provider
        </Button>
      </ProcessContainer>
    </PageContainer>
  );
}
