import * as t from "io-ts";
import { BooleanFromString } from "io-ts-types/BooleanFromString";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { UUID as uuidCodec } from "io-ts-types/UUID";

import {
  stringEnumValues,
  oneOfLiteralCodec,
  stringEnumCodec,
} from "../codecs";
import { emailAddressCodec } from "../codecs/contact";
import {
  cryptoCurrencyCodec,
  cryptoCurrencyValueCodec,
} from "../codecs/crypto";
import {
  currencyAmountCodec,
  currencyCodec,
  currencyValueCodec,
} from "../codecs/currency";
import {
  personalDonationChargeResponseCodec,
  personalDonationResponseCodec,
  donationFrequencyCodec,
  paymentSourceResponseCodec,
  personalGivingCreditResponseCodec,
  givingCreditResponseCodec,
  signupIncentiveCampaignResponseCodec,
} from "../codecs/entities";
import {
  integerFromStringCodec,
  nonNegativeIntegerFromStringCodec,
} from "../codecs/number";
import { commentTextCodec, emptyStringAsNullCodec } from "../codecs/text";
import {
  DonationFrequency,
  GetUnclaimedGivingCreditStatus,
  PaymentMethod,
  PaymentSourceType,
} from "../entity/types";
import { analyticsCodec, utmMetadataCodec } from "../helpers/analytics";
import { HttpMethod } from "../helpers/http";
import { MinTwoElemsArray } from "../helpers/types";

import { makeRouteSpec } from ".";

export const donationMatchCampaignIdentifierCodec = t.partial({
  id: uuidCodec,
  title: t.string,
  code: t.union([t.string, t.null]),
});
export type DonationMatchCampaignIdentifier = t.TypeOf<
  typeof donationMatchCampaignIdentifierCodec
>;

export const partnerWebhookCodec = t.type({ token: t.string });

export const baseCreateDonationBodyCodec = t.intersection([
  t.type({
    toNonprofitId: uuidCodec,
    nonce: NonEmptyString,
  }),
  t.partial({
    partnerWebhook: partnerWebhookCodec,
    externalPaymentSourceId: t.string,
    /**
     * ID of original donation that influenced the donor to make this new one
     */
    donationToJoinId: uuidCodec,
    /**
     * Permission to share the donors info with the nonprofit.
     */
    shareInfo: t.boolean,
    isPublic: t.boolean,
    /**
     * Metadata that we may want to add to the donation.
     */
    metadata: t.intersection([
      t.intersection([utmMetadataCodec, analyticsCodec]),
      t.partial({
        entryRouteName: t.string,
        entryEntityName: t.string,
      }),
      /* Funds alternative to donate to multiple arbitrary nonprofits */
      t.partial({
        payToNonprofits: t.array(t.union([t.string, uuidCodec])),
        payToNonprofitWeights: t.array(t.number),
        payToNonprofitMatchings: t.array(t.number),
        recurringMatches: integerFromStringCodec,
      }),
      t.partial({
        optInToMailingList: t.boolean,
      }),
      t.partial({
        tipValueKind: t.string, // Placeholder to store if the tip was an absolute / percentage / relative amount
        usdValueAtPledge: currencyAmountCodec,
        pledgedCryptoValue: cryptoCurrencyValueCodec,
        pledgedEquityValue: t.type({ symbol: t.string, amount: t.number }),
        pledgedTipQuantity: t.union([
          t.type({ symbol: t.string, amount: t.number }),
          cryptoCurrencyValueCodec,
          currencyValueCodec,
        ]),
        pledgedAmount: currencyAmountCodec,
        pledgedTipAmount: currencyAmountCodec,
        coverFeeWithTip: t.boolean, // Cover processing fees with tips test
      }),
    ]),
    /**
     * ID allowing partners to track donors they send to Every.org
     */
    partnerDonationId: t.string,
    /**
     * Cloudflare's turnstile token
     */
    cfTurnstileToken: t.union([t.string, t.undefined]),
    // Partners metadata forwarding
    partnerMetadata: t.string,
    // Payment source type to specify between options like chariot or regular daf donations
    paymentSourceType: stringEnumCodec({
      name: "paymentSourceType",
      enumObject: PaymentSourceType,
    }),
  }),
]);

export const giftCardPurchaseInfoCodec = t.intersection([
  t.type({
    amount: currencyValueCodec,
    quantity: nonNegativeIntegerFromStringCodec,
  }),
  t.partial({
    nonprofitId: t.string,
    tagId: t.string,
  }),
]);

export type GiftCardPurchaseInfo = t.TypeOf<typeof giftCardPurchaseInfoCodec>;

export const createDonationBodyCodec = t.intersection([
  baseCreateDonationBodyCodec,
  t.union([
    t.type({
      paymentMethod: t.union([
        t.literal(PaymentMethod.STRIPE),
        t.literal(PaymentMethod.PAYPAL),
        t.literal(PaymentMethod.DAF),
      ]),
      value: currencyValueCodec,
      frequency: donationFrequencyCodec,
      creditAmount: nonNegativeIntegerFromStringCodec,
    }),
    t.type({
      paymentMethod: t.union([
        t.literal(PaymentMethod.CRYPTO),
        t.literal(PaymentMethod.MUTUAL_FUND),
        t.literal(PaymentMethod.STOCKS),
      ]),
      value: t.undefined,
      frequency: t.literal(DonationFrequency.ONCE),
      creditAmount: t.undefined,
    }),
  ]),
  t.partial({
    // TODO: once this has been deployed, we can make this member required
    // but nullable to avoid missing it
    payToNonprofitId: uuidCodec,
    /**
     * These are present only on guest donations made via Apple / Google pay.
     * All other donors should already have a guest user.
     */
    payerEmail: emailAddressCodec,
    payerName: t.string,
    /**
     * DonationMatchCampaign this donation was a part of
     */
    matchCampaign: donationMatchCampaignIdentifierCodec,
    /**
     * Fundraiser associated with the donation
     */
    fromFundraiserId: uuidCodec,
    /**
     * Gift card purchase info
     */
    giftCardPurchaseInfo: giftCardPurchaseInfoCodec,
    /**
     * Donation tip to every.org amount
     */
    tipAmount: nonNegativeIntegerFromStringCodec,
    /**
     * Private note to the nonprofit admins
     */
    privateNote: t.union([commentTextCodec, emptyStringAsNullCodec, t.null]),
    /**
     * Public comment
     */
    commentText: t.union([commentTextCodec, emptyStringAsNullCodec, t.null]),
    /**
     * Specify the designation for the donation
     */
    designation: t.union([t.string, emptyStringAsNullCodec, t.null]),
    /**
     * Allow user to make a "double donation"
     * https://github.com/everydotorg/every.org/issues/15152
     */
    allowDoubleDonations: t.boolean,
    givingCreditToRedeem: t.string,
  }),
]);

export enum DonationMatchStatus {
  APPLIED = "APPLIED",
  NEEDS_SIGNUP = "NEEDS_SIGNUP",
  ALREADY_CLAIMED = "ALREADY_CLAIMED",
  INVALID_CODE = "INVALID_CODE",
  INELIGIBLE = "INELIGIBLE",
  DEPLETED = "DEPLETED",
  ERROR = "ERROR",
  CRYPTO_DELAYED = "CRYPTO_DELAYED",
  AWAITING_PAYMENT = "AWAITING_PAYMENT",
}

export const donationMatchDataCodec = t.intersection([
  t.type({ matchCampaign: donationMatchCampaignIdentifierCodec }),
  t.union([
    t.type({
      status: t.union([
        t.literal(DonationMatchStatus.APPLIED),
        t.literal(DonationMatchStatus.NEEDS_SIGNUP),
      ]),
      value: currencyValueCodec,
    }),
    t.type({
      status: oneOfLiteralCodec(
        stringEnumValues({ enumObject: DonationMatchStatus }).filter(
          (v) =>
            v !== DonationMatchStatus.APPLIED &&
            v !== DonationMatchStatus.NEEDS_SIGNUP
        ) as MinTwoElemsArray<
          Exclude<
            DonationMatchStatus,
            DonationMatchStatus.APPLIED | DonationMatchStatus.NEEDS_SIGNUP
          >
        >
      ),
    }),
  ]),
]);
export type DonationMatchData = t.TypeOf<typeof donationMatchDataCodec>;

export const createDonationRouteSpec = makeRouteSpec({
  path: "/donate",
  method: HttpMethod.POST,
  authenticated: false,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: createDonationBodyCodec,
  responseBodyCodec: t.intersection([
    t.type({
      donationCharge: personalDonationChargeResponseCodec,
      donation: personalDonationResponseCodec,
    }),
    t.partial({
      match: donationMatchDataCodec,
      redeemedSignupIncentiveCampaign: signupIncentiveCampaignResponseCodec,
    }),
  ]),
});

export const retryDonationChargeRouteSpec = makeRouteSpec({
  path: "/donationCharge/:id/retry",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({
    donationCharge: personalDonationChargeResponseCodec,
    donation: personalDonationResponseCodec,
  }),
});

/**
 * Route to create or update a comment.
 *
 * Use this to update comments for an individual donation,
 * a recurring donation, or both (in which case the donation
 * must belong to this recurring donation).
 */
export const updateCommentRouteSpec = makeRouteSpec({
  path: "/updateComment",
  method: HttpMethod.PUT,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({
    commentText: t.union([commentTextCodec, emptyStringAsNullCodec, t.null]),
    donationId: uuidCodec,
  }),
  responseBodyCodec: t.type({
    donation: personalDonationResponseCodec,
  }),
});

const getUnclaimedGivingCreditResponseCodec = t.union([
  t.type({
    status: t.literal(GetUnclaimedGivingCreditStatus.FOUND),
    givingCredit: personalGivingCreditResponseCodec,
  }),
  t.type({
    status: t.literal(GetUnclaimedGivingCreditStatus.INVALID_CODE),
    givingCredit: t.undefined,
  }),
  t.type({
    status: t.literal(GetUnclaimedGivingCreditStatus.ALREADY_CLAIMED),
    givingCredit: personalGivingCreditResponseCodec,
  }),
  t.type({
    status: t.literal(GetUnclaimedGivingCreditStatus.EXPIRED),
    givingCredit: t.undefined,
  }),
]);
export type GetUnclaimedGivingCreditResponse = t.TypeOf<
  typeof getUnclaimedGivingCreditResponseCodec
>;
export const getUnclaimedGivingCreditRouteSpec = makeRouteSpec({
  path: "/givingCredit",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({}),
  paramsCodec: t.type({ code: t.string }),
  bodyCodec: t.type({}),
  responseBodyCodec: getUnclaimedGivingCreditResponseCodec,
});

/**
 * Route to attach an unclaimed giving credit to a user.
 */
export const attachGivingCreditRouteSpec = makeRouteSpec({
  path: "/attachGivingCredit",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({
    randomSecret: t.string,
  }),
  responseBodyCodec: personalGivingCreditResponseCodec,
});

export const givingCreditsForDonationRouteSpec = makeRouteSpec({
  path: "/givingCredit/order/:donationId",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ donationId: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.array(givingCreditResponseCodec),
});

/**
 * Route to attach a Stripe payment Source to a Customer.
 */
const attachPaymentSourceBodyCodec = t.intersection([
  t.type({
    paymentSourceId: t.string,
  }),
  t.partial({ cfTurnstileToken: t.string }),
]);
export const attachPaymentSourceRouteSpec = makeRouteSpec({
  path: "/addPaymentSource",
  method: HttpMethod.POST,
  authenticated: false,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: attachPaymentSourceBodyCodec,
  responseBodyCodec: paymentSourceResponseCodec,
});

/**
 * Route to detach a Stripe payment Source from a Customer.
 */
const detachPaymentSourceBodyCodec = t.type({
  paymentSourceId: t.string,
});
export const detachPaymentSourceRouteSpec = makeRouteSpec({
  path: "/removePaymentSource",
  method: HttpMethod.DELETE,
  authenticated: false,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: detachPaymentSourceBodyCodec,
  responseBodyCodec: t.type({}),
});

/**
 * Route to detach a Stripe payment Source from a Customer.
 */
const setDefaultPaymentSourceBodyCodec = t.type({
  paymentSourceId: t.string,
});
export const setDefaultPaymentSourceRouteSpec = makeRouteSpec({
  path: "/setDefaultPaymentSource",
  method: HttpMethod.PATCH,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: setDefaultPaymentSourceBodyCodec,
  responseBodyCodec: t.type({}),
});

/**
 * Route to list Stripe payment Sources.
 */
const getPaymentSourcesResponseCodec = t.type({
  sources: t.array(paymentSourceResponseCodec),
});
export const getPaymentSourcesRouteSpec = makeRouteSpec({
  path: "/paymentSources",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: getPaymentSourcesResponseCodec,
});
export type GetPaymentSourcesResponse = t.TypeOf<
  typeof getPaymentSourcesResponseCodec
>;

/**
 * Expected format from Plaid Link
 * @see https://plaid.com/docs/link/web/#link-web-onsuccess-account
 */
const plaidAccountType = t.type({
  id: t.string,
  name: t.string,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  verification_status: t.union([t.string, t.null, t.undefined]),
  mask: t.union([t.string, t.null, t.undefined]),
});

export const addPlaidAccountRouteSpec = makeRouteSpec({
  path: "/addPlaidAccount",
  method: HttpMethod.POST,
  authenticated: false,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({
    publicToken: t.string,
    institutionName: t.union([t.string, t.null]),
    accounts: t.array(plaidAccountType),
    linkSessionId: t.string,
  }),
  responseBodyCodec: paymentSourceResponseCodec,
});

export const createPlaidLinkTokenRouteSpec = makeRouteSpec({
  path: "/createPlaidLinkToken",
  method: HttpMethod.POST,
  authenticated: false,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.partial({
    // Optional; include for a link token for a specific payment source
    paymentSourceId: t.string,
  }),
  responseBodyCodec: t.type({
    linkToken: t.string,
  }),
});

export const archiveDonationRouteSpec = makeRouteSpec({
  path: "/archiveDonation",
  method: HttpMethod.POST,
  authenticated: false,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.intersection([
    t.type({ donationId: uuidCodec }),
    t.partial({
      cancelAndExpireNewCharge: BooleanFromString,
    }),
  ]),
  responseBodyCodec: t.type({ success: t.boolean }),
});

export const getPartnerWebhookDetailsRouteSpec = makeRouteSpec({
  path: "/partnerWebhooks/:token",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ token: t.string }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({ partnerName: t.string }),
});

export const getCryptoTokenRateRouteSpec = makeRouteSpec({
  path: "/crypto/:token/rate",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ token: cryptoCurrencyCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({
    cryptoCurrency: cryptoCurrencyCodec,
    currency: currencyCodec,
    rate: t.number,
  }),
  publicRoute: {
    publicCacheLengthMinutes: 1,
    alsoCacheIfAuthenticated: true,
  },
});

export const createPaypalOrderRouteSpec = makeRouteSpec({
  path: "/paypal/createOrder",
  method: HttpMethod.POST,
  authenticated: false,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({
    donationChargeId: uuidCodec,
  }),
  responseBodyCodec: t.type({
    // complete paypal order schema https://developer.paypal.com/docs/api/orders/v2/#orders_create
    order: t.union([t.partial({ id: t.string }), t.record(t.string, t.string)]),
  }),
});

export const capturePaypalOrderRouteSpec = makeRouteSpec({
  path: "/paypal/captureOrder",
  method: HttpMethod.POST,
  authenticated: false,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({
    orderId: t.string,
  }),
  responseBodyCodec: t.type({
    // complete paypal order schema https://developer.paypal.com/docs/api/orders/v2/#orders_capture
    order: t.partial({
      id: t.string,
      details: t.array(
        t.type({
          issue: t.string,
          description: t.string,
        })
      ),
    }),
  }),
});

export const createPaypalSubscriptionRouteSpec = makeRouteSpec({
  path: "/paypal/createSubscription",
  method: HttpMethod.POST,
  authenticated: false,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.type({
    donationChargeId: uuidCodec,
  }),
  responseBodyCodec: t.type({
    // complete paypal subscription schema https://developer.paypal.com/docs/api/subscriptions/v1/#subscriptions_create
    subscription: t.type({
      id: t.string,
      status: t.string,
    }),
  }),
});
