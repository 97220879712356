/**
 * Returns midnight on the next day.
 */
export function tomorrowMidnightUtc(today?: Date) {
  const date = today ? new Date(today) : new Date();
  date.setUTCHours(0, 0, 0, 0);
  date.setUTCDate(date.getUTCDate() + 1);
  return date;
}

/**
 * Returns a date (without time) to the start of the day
 * in PST / PDT hours.
 */
export function setDateToTheStartOfTheDayPST(date: Date) {
  const hour = pacificTimezoneHoursOffset(date);
  const utcDateStr = date.toISOString();
  const [day] = utcDateStr.split("T");
  return new Date(`${day}T0${hour}:00:01.000Z`);
}

/**
 * Returns a date (without time) to the end of the day
 * in PST / PDT hours.
 */
export function setDateToTheEndOfTheDayPST(date: Date) {
  const hour = pacificTimezoneHoursOffset(date);
  const nextDay = new Date(date);
  nextDay.setUTCDate(nextDay.getUTCDate() + 1);
  const utcDateStr = nextDay.toISOString();
  const [day] = utcDateStr.split("T");
  return new Date(`${day}T0${hour - 1}:59:59.000Z`);
}

/**
 * Returns a date with time in utc with hours to
 * the date without hours in the user's timezone
 */
export function setDateToTheUsersTimezoneWithoutHours({
  date,
  startOfTheDay,
}: {
  date: Date;
  startOfTheDay: boolean;
}) {
  const dateStr = date.toLocaleString("sv-SE"); // YYYY-MM-dd, HH:mm:ss
  if (startOfTheDay) {
    return new Date(dateStr);
  }
  const [day] = dateStr.split(" ");
  return new Date(`${day}T00:00:00.000Z`);
}

/**
 * Returns today at the beginning of the day
 */
export function todayAtTheStartOfTheDay() {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
}

/**
 * Returns today at the end of the day pstpdt
 */
export function todayAtTheEndOfTheDayPST() {
  const date = new Date();
  return endOfTheDayPST(date);
}

function endOfTheDayPST(date: Date) {
  const hour = date.getUTCHours();
  const pacificMidnightHour = pacificTimezoneHoursOffset(date);
  if (hour >= pacificMidnightHour) {
    date.setUTCDate(date.getUTCDate() + 1);
  }
  date.setUTCHours(pacificMidnightHour - 1, 59, 59, 0);
  return date;
}

/**
 * Returns the amount of hours difference relative to UTC time
 * for the pacific.
 * This useful to determine if we are at PST or PDT time.
 */
function pacificTimezoneHoursOffset(date?: Date) {
  const postfix = new Intl.DateTimeFormat("en-US", {
    timeZone: "America/Los_Angeles",
    timeZoneName: "short",
  })
    .format(date || new Date())
    .slice(-3);
  if (postfix === "PDT") {
    return 7;
  }
  return 8;
}

export const MILLISECONDS_IN_A_DAY = 1000 * 24 * 60 * 60;

export function daysAgo(date: Date) {
  return (Date.now() - date.getTime()) / MILLISECONDS_IN_A_DAY;
}

/**
 * Returns the beginning of the given year in PST (midnight UTC + 8 hours).
 * Used to create the bounds to check for donations made in a given year. We
 * use PST as a sensible default, but we should do something more robust once
 * we have user timezones.
 * @param year Year string - i.e. "2019"
 */
export function beginningOfYearPst(year: number) {
  const date = new Date(`${year}-01-01`);
  date.setUTCHours(8);
  return date;
}

/**
 * Given a date like Date("02-03-2020"), returns "February"
 * https://stackoverflow.com/a/18648314/888970
 */
export function getMonthNameFromDate(date: Date, shorten?: boolean) {
  return date.toLocaleString("default", { month: shorten ? "short" : "long" });
}

/**
 * Returns string representation of date (either given, or current time)
 * without the time, eg 2020-11-22.
 */
export function getDateString(date?: Date) {
  return (date || new Date()).toISOString().substr(0, "yyyy-mm-dd".length);
}

export function getPrettyDayFromDate(date: Date) {
  const dayString = date.toLocaleString("default", { day: "numeric" });
  switch (dayString.substr(-1)) {
    case "1":
      return `${dayString}st`;
    case "2":
      return `${dayString}nd`;
    case "3":
      return `${dayString}rd`;
    default:
      return `${dayString}th`;
  }
}

/**
 * Compares the dates of to base entities for sorting.
 */
export function dateSortComparisonBaseEntity(
  a: { createdAt: Date },
  b: { createdAt: Date }
) {
  return b.createdAt.getTime() - a.createdAt.getTime();
}

/**
 *  Returns the amount of days between 2 dates
 */
export function daysDifference(date1: Date, date2: Date) {
  return Math.abs(date2.getTime() - date1.getTime()) / (24 * 60 * 60 * 1000);
}
