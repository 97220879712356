import {
  Button,
  ButtonRole,
  ButtonTargetKind,
  ButtonSize,
} from "@components/Button";
import { CopyToClipboardButton } from "@components/CopyToClipboardButton";
import { IconDisplay, IconSize, Icon } from "@components/Icon";
import { Loading } from "@components/LoadingIndicator";
import { DonationSummary } from "@components/donate/DonateV3/PaymentProcess/components/DonationSummary";
import {
  PageContainer,
  ProcessContainer,
} from "@components/donate/DonateV3/PaymentProcess/components/PageContainer";
import {
  PaymentProcessRouteName,
  paymentProcessRouteNameToPathMap,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import { SelectDonationFlowPaymentOption } from "@components/donate/DonateV3/PaymentProcess/components/SelectFlowPaymentOption";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import { useSyncPaymentMethod } from "@components/donate/DonateV3/PaymentProcess/useSyncPaymentMethod";
import {
  CreateOrUpdateDonationResult,
  DonateFormType,
  DonateModalAction,
  DONATE_FORM_ERROR,
} from "@components/donate/DonateV3/types";
import { css } from "@emotion/react";
import Big from "big.js";
import React, { useCallback, useContext, useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";

import { spacing } from "@every.org/common/src/display/spacing";
import { DonationFlowPaymentOption } from "@every.org/common/src/entity/types";
import { CryptoCurrency } from "@every.org/common/src/entity/types/crypto";
import {
  getRoutePath,
  ClientRouteName,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import {
  MAX_CRYPTO_DECIMALS_FOR_DISPLAY,
  displayContractType,
  parsePaymentSourceIdToAddress,
} from "@every.org/common/src/helpers/cryptoCurrency";
import { notUndefined } from "@every.org/common/src/helpers/objectUtilities";

import { AuthContext } from "src/context/AuthContext";
import { AuthStatus } from "src/context/AuthContext/types";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { horizontalStackCss, verticalStackCss } from "src/theme/spacing";
import { FontWeight, TextSize, textSizeCss } from "src/theme/text";
import { CryptoCurrencyConfig } from "src/utility/cryptoCurrency";
import { displayCryptoValueInUserLocale } from "src/utility/currency";
import { getWindow } from "src/utility/window";

export const CryptoV2Confirmation = ({
  nonprofit,
  form,
  formContext,
  submitDonation,
  handleConfirmedDonation,
  createOrUpdateDonationResult,
  paymentOption,
}: {
  nonprofit: ContextNonprofit;
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
  submitDonation: (
    formValues: DonateFormType
  ) => Promise<CreateOrUpdateDonationResult | undefined>;
  handleConfirmedDonation: (result: CreateOrUpdateDonationResult) => boolean;
  paymentOption: DonationFlowPaymentOption;
  createOrUpdateDonationResult?: CreateOrUpdateDonationResult;
}) => {
  const {
    formState: { errors },
    watch,
  } = form;

  const authState = useContext(AuthContext);

  const navigate = useNavigate();
  const router = useEdoRouter();

  const { cryptoCurrency, cryptoPledgeAmount, amountToEveryOrg } = watch();
  const { walletAddress, memo, depositTag } = parsePaymentSourceIdToAddress(
    cryptoCurrency,
    createOrUpdateDonationResult?.donationCharge?.externalPaymentSourceId
  );

  useEffect(() => {
    getWindow()?.scrollTo({ top: 0 });
  }, []);

  useSyncPaymentMethod({ paymentOption, form, formContext });

  const clearFormError = useCallback(async () => {
    if (errors.DONATE_FORM_ERROR) {
      form.clearErrors(DONATE_FORM_ERROR);
      await formContext.refreshNonce();
    }
  }, [form, formContext, errors]);

  const { setCreateOrUpdateDonationResult, refreshNonce } = formContext;

  const goBack = useCallback(() => {
    clearFormError();
    // We need to get a new donation if the user is going back to change the
    // token they want to donate
    setCreateOrUpdateDonationResult(undefined);
    refreshNonce();
    navigate(-1);
  }, [clearFormError, setCreateOrUpdateDonationResult, refreshNonce, navigate]);

  const { nonprofitMatchCampaign } = formContext;
  const displayShareMatchForm =
    nonprofitMatchCampaign?.matchShare &&
    nonprofitMatchCampaign?.shareMatchAmount &&
    nonprofitMatchCampaign?.shareMatchAmount > 0;

  const handleDonationComplete = useCallback(() => {
    if (
      createOrUpdateDonationResult &&
      !handleConfirmedDonation(createOrUpdateDonationResult)
    ) {
      if (formContext.donateAction === DonateModalAction.UPDATE) {
        router.push(
          getRoutePath({
            name: ClientRouteName.MY_GIVING_RECURRING,
            format: URLFormat.RELATIVE,
            query: {},
          })
        );
        return;
      }
      navigate(
        displayShareMatchForm
          ? paymentProcessRouteNameToPathMap[
              PaymentProcessRouteName.SHARE_MATCH
            ]
          : paymentProcessRouteNameToPathMap[PaymentProcessRouteName.THANK_YOU]
      );
    }
  }, [
    handleConfirmedDonation,
    formContext.donateAction,
    createOrUpdateDonationResult,
    navigate,
    router,
    displayShareMatchForm,
  ]);

  if (authState.status === AuthStatus.LOADING) {
    return <Loading />;
  }

  if (!cryptoCurrency || !cryptoPledgeAmount) {
    navigate(paymentProcessRouteNameToPathMap[PaymentProcessRouteName.CRYPTO], {
      replace: true,
    });
    return null;
  }

  const config = CryptoCurrencyConfig[cryptoCurrency];

  const qrCodeAddress =
    walletAddress && cryptoCurrency === CryptoCurrency.XRP && depositTag
      ? `xrpl:${walletAddress}?dt=${depositTag}`
      : walletAddress;

  return (
    <PageContainer>
      {formContext.skipAmountAndFrequency ? (
        <SelectDonationFlowPaymentOption
          selectedPaymentOption={paymentOption}
          paymentRequestReadyStatus={
            formContext.paymentRequestInitializer.readyStatus
          }
          paymentRequestIsApplePay={
            formContext.paymentRequestInitializer.isApplePay
          }
          paymentFlowOptions={formContext.paymentFlowOptions}
          showMorePaymentOptions={formContext.showMorePaymentOptions}
          setShowMorePaymentOptions={formContext.setShowMorePaymentOptions}
        />
      ) : (
        <div
          css={css`
            padding: 20px;
            ${cssForMediaSize({
              max: MediaSize.MEDIUM_LARGE,
              css: css`
                padding: 0;
                margin-bottom: ${spacing.l};
              `,
            })};
          `}
        >
          <Button
            role={ButtonRole.TEXT_ONLY}
            onClick={{ kind: ButtonTargetKind.FUNCTION, action: goBack }}
            data-tname={`donateClickBack__${paymentOption}`}
            contentCss={horizontalStackCss.xxs}
          >
            <Icon
              iconImport={() => import("@components/Icon/icons/ArrowBackIcon")}
              size={IconSize.MEDIUM}
              display={IconDisplay.ACCENT}
            />
            <span>Back</span>
          </Button>
        </div>
      )}
      <ProcessContainer>
        <form
          css={css`
            ${verticalStackCss.xl}
            > :not(:last-child) {
              margin-bottom: ${spacing.l};
            }
          `}
        >
          <h4
            css={css`
              ${textSizeCss[TextSize.m]};
              font-weight: ${FontWeight.REGULAR};
              strong {
                font-weight: ${FontWeight.BOLD};
              }
            `}
          >
            To complete your donation, send{" "}
            {
              <strong>
                {displayCryptoValueInUserLocale({
                  cryptoCurrency: cryptoCurrency,
                  amount:
                    formContext.totalCryptoAmount ||
                    new Big(cryptoPledgeAmount),
                  maximumSignificantDigits: MAX_CRYPTO_DECIMALS_FOR_DISPLAY,
                })}
              </strong>
            }{" "}
            from your crypto wallet to the address below.
          </h4>
          <div
            css={css`
              ${verticalStackCss.l};
              text-align: center;
              ${textSizeCss[TextSize.xs]};
            `}
          >
            <div>
              {qrCodeAddress && (
                <QRCode
                  size={225}
                  style={{ height: "auto", maxWidth: "225px", width: "225px" }}
                  value={qrCodeAddress}
                  viewBox={`0 0 225 225`}
                />
              )}
            </div>
            {walletAddress && (
              <p>
                {(memo || depositTag) && (
                  <span
                    css={css`
                      font-weight: ${FontWeight.BOLD};
                    `}
                  >
                    Address:{" "}
                  </span>
                )}
                {walletAddress}
              </p>
            )}
            <div css={verticalStackCss.s}>
              {walletAddress && (
                <CopyToClipboardButton
                  data-tname="ChallengeCommentCopy"
                  role={ButtonRole.SECONDARY}
                  size={ButtonSize.SMALL}
                  textToCopy={walletAddress}
                  css={{ width: "212px", margin: "0 auto" }}
                  beforeCopiedText="Copy wallet address"
                  afterCopiedText="Wallet address copied!"
                />
              )}
              <p
                css={css`
                  text-align: center;
                  color: var(${colorCssVars.text.secondary});
                  ${textSizeCss[TextSize.xs]};
                `}
              >
                Send only{" "}
                {[
                  config.abbreviation,
                  config.contractType
                    ? `(${displayContractType(config.contractType, false)})`
                    : undefined,
                ]
                  .filter(notUndefined)
                  .join(" ")}{" "}
                to this address. Sending other unsupported tokens may result in
                loss of your donation.
              </p>
            </div>
            {memo && (
              <p>
                <span
                  css={css`
                    font-weight: ${FontWeight.BOLD};
                  `}
                >
                  Memo:{" "}
                </span>
                {memo}
              </p>
            )}
            {depositTag && (
              <React.Fragment>
                <p>
                  <span
                    css={css`
                      font-weight: ${FontWeight.BOLD};
                    `}
                  >
                    Deposit tag:{" "}
                  </span>
                  {depositTag}
                </p>
                <div css={verticalStackCss.s}>
                  <CopyToClipboardButton
                    data-tname="DepositAddressCopy"
                    role={ButtonRole.SECONDARY}
                    size={ButtonSize.SMALL}
                    textToCopy={depositTag}
                    css={{ width: "212px", margin: "0 auto" }}
                    beforeCopiedText="Copy deposit tag"
                    afterCopiedText="Deposit tag copied!"
                  />
                  <p
                    css={css`
                      text-align: center;
                      color: var(${colorCssVars.text.secondary});
                      ${textSizeCss[TextSize.xs]};
                    `}
                  >
                    Please ensure you enter the address and the tag exactly as
                    instructed. Failure to do so may result in loss of funds.
                  </p>
                </div>
              </React.Fragment>
            )}
          </div>
          {amountToEveryOrg && new Big(amountToEveryOrg).gt(0) && (
            <DonationSummary
              form={form}
              formContext={formContext}
              nonprofit={nonprofit}
              paymentOption={DonationFlowPaymentOption.CRYPTO}
            />
          )}
          <Button
            role={ButtonRole.PRIMARY}
            onClick={{
              kind: ButtonTargetKind.FUNCTION,
              action: () => {
                handleDonationComplete();
              },
            }}
            data-tname="completeCryptoDonation"
            contentCss={{ textAlign: "center" }}
          >
            I’ve sent my donation
          </Button>
        </form>
      </ProcessContainer>
    </PageContainer>
  );
};
