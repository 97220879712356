import {
  ButtonRole,
  ButtonSize,
  Button,
  ButtonTargetKind,
} from "@components/Button";
import {
  ShareButton,
  getSupportNonprofitShareData,
} from "@components/ShareButton";
import { SupportedBy } from "@components/SupportedBy";
import {
  FeedCardActionsContainer,
  ShareAndLikeContainer,
  PrimaryActionsContainer,
} from "@components/feed/DonationCardActions";
import { LikeButtonWithCounter } from "@components/feed/LikeButton";
import { DonateButton } from "@components/layout/DonateButton";
import React from "react";

import {
  LikeableType,
  PaymentMethod,
} from "@every.org/common/src/entity/types";
import {
  getRoutePath,
  ClientRouteName,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { spacing } from "src/theme/spacing";
import { ClickAction } from "src/utility/analytics";
import { nonprofitShareImageCloudinaryParams } from "src/utility/cloudinary/nonprofit";
import {
  INSTAGRAM_POST_DIMENSIONS,
  DEFAULT_SHARE_IMAGE_CLOUDINARY_PARAMS,
} from "src/utility/opengraph";

interface ActionButtonsProps {
  nonprofit: ContextNonprofit;
  donateCrypto?: boolean;
}

export function DefaultActionButtons({
  nonprofit,
  donateCrypto = false,
}: ActionButtonsProps) {
  const { primarySlug, supporterInfo } = nonprofit;

  return (
    <FeedCardActionsContainer>
      <ShareAndLikeContainer>
        <ShareButton
          isFeedCardShare
          data-tname="shareNonprofitButton"
          data-action={ClickAction.SHARE}
          role={ButtonRole.TEXT_ONLY}
          size={ButtonSize.SMALL}
          hideText
          buttonCss={{ padding: spacing.xxs }}
          shareData={(medium) =>
            getSupportNonprofitShareData({
              medium,
              nonprofit,
              body: nonprofit?.description || undefined,
              url: getRoutePath({
                name: ClientRouteName.NONPROFIT_OR_CAUSE,
                tokens: { nonprofitSlug: nonprofit.primarySlug },
                format: URLFormat.ABSOLUTE,
              }),
            })
          }
          instagramImageCloudinaryParams={
            nonprofitShareImageCloudinaryParams({
              logoCloudinaryId: nonprofit.logoCloudinaryId,
              coverImageCloudinaryId: nonprofit.coverImageCloudinaryId,
              imageHeight: INSTAGRAM_POST_DIMENSIONS.height,
            }) || DEFAULT_SHARE_IMAGE_CLOUDINARY_PARAMS
          }
        />
        <LikeButtonWithCounter
          data-tname="likeButton"
          data-action={ClickAction.LIKE}
          role={ButtonRole.TEXT_ONLY}
          size={ButtonSize.SMALL}
          type={LikeableType.NONPROFIT}
          id={nonprofit.id}
          likeCount={nonprofit.likesInfo?.count || null}
          loggedInUserLikes={nonprofit.likesInfo?.hasLoggedInUserLiked || null}
        />
      </ShareAndLikeContainer>
      <PrimaryActionsContainer>
        {supporterInfo && supporterInfo.numSupporters > 0 && (
          <Button
            data-tname={"NonprofitCard--supporterCount"}
            data-action={ClickAction.SUPPORTERS}
            size={ButtonSize.SMALL}
            role={ButtonRole.TEXT_ONLY}
            onClick={{
              kind: ButtonTargetKind.LINK,
              to: getRoutePath({
                format: URLFormat.RELATIVE,
                name: ClientRouteName.NONPROFIT_OR_CAUSE,
                tokens: { nonprofitSlug: primarySlug },
              }),
              onClick: (e) => e.stopPropagation(),
            }}
          >
            <SupportedBy numSupporters={supporterInfo.numSupporters} />
          </Button>
        )}
        {!nonprofit.metadata?.hideDonateButtons && (
          <DonateButton
            data-tname="NonprofitCard--donate"
            data-action={ClickAction.DONATE}
            donationsEnabled={nonprofit.donationsEnabled}
            primarySlug={nonprofit.primarySlug}
            method={donateCrypto ? PaymentMethod.CRYPTO : undefined}
          >
            Donate
          </DonateButton>
        )}
      </PrimaryActionsContainer>
    </FeedCardActionsContainer>
  );
}
