import { TextInput } from "@components/TextInput";
import { truncateDecimalPoint } from "@components/TextInput/AmountTextInput";
import { getInitialSliderSettingsForStocks } from "@components/donate/DonateV3/PaymentProcess/components/AddTip/StockTipSlider";
import { AddTipProps } from "@components/donate/DonateV3/PaymentProcess/components/AddTip/types";
import { css } from "@emotion/react";
import Big from "big.js";
import React, { useCallback, useEffect, useState } from "react";

import { spacing } from "src/theme/spacing";
import { FontWeight } from "src/theme/text";

export const StockTipCustom = ({
  value,
  onChange,
  form,
  formContext,
  amountBig,
}: AddTipProps) => {
  const stockAmount = form.watch("stockAmount");
  const defaultTip = getInitialSliderSettingsForStocks(
    stockAmount ?? 0
  ).initial.toString();
  const [inputValue, setInputValue] = useState<string>();
  const isDecimalAmount =
    typeof stockAmount === "number" && !Number.isInteger(stockAmount);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    const defaultTipValue = isDecimalAmount
      ? new Big(stockAmount).div(100).mul(defaultTip).toString()
      : defaultTip;
    setInputValue(defaultTipValue);
    onChange(defaultTipValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (e.currentTarget.value === "") {
        return;
      }
      onChange(truncateDecimalPoint(e.currentTarget.value));
    },
    [onChange]
  );

  const onAmountChanged = (val: string) => {
    if (val !== "") {
      if (!/^\d*\.?\d*$/.test(val)) {
        return false;
      }
    }
    if (val === ".") {
      setInputValue("0.");
      return false;
    }
    return true;
  };

  return (
    <TextInput
      name="stockTipAmount"
      id="stockTipAmount"
      data-tname="stockTipAmount"
      inputSuffix={
        inputValue && parseFloat(inputValue) > 1 ? "shares" : "share"
      }
      inputBoxCss={css`
        input {
          text-align: right;
          font-weight: ${FontWeight.BOLD};
          margin-right: ${spacing.xxs};
        }
      `}
      onChange={(e) => {
        if (onAmountChanged(e.target.value)) {
          setInputValue(e.target.value);
          if (e.target.value === ".") {
            setInputValue("0.");
          }
          onChange(e.target.value);
        }
      }}
      value={inputValue || ""}
      onBlur={onBlur}
      collapseDescriptionSpace
    />
  );
};
