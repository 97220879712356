import { useCallback, useState } from "react";

import {
  MatchingCampaignResponse,
  NonEmptyMatchingCampaignResponse,
} from "@every.org/common/src/codecs/entities";
import {
  FGC_CAMPAIGN_CODE,
  SPECIAL_NONPROFIT_IDS,
} from "@every.org/common/src/entity/constants";
import { displayFGCSpec } from "@every.org/common/src/entity/types/configVariable";
import { getDonationMatchCampaignRouteSpec } from "@every.org/common/src/routes/campaign";

import {
  useConfigVariable,
  UseConfigVariableStatus,
} from "src/context/ConfigVariableContext";
import { useAsyncEffect } from "src/hooks/useAsyncEffect";
import { queryApi } from "src/utility/apiClient";
import { logger } from "src/utility/logger";

export function useFallGivingChallengeActive(nonprofitId?: string) {
  const [FGCCampaign, setFGCCampaign] = useState<
    NonEmptyMatchingCampaignResponse | undefined
  >();
  const FGCConfigVar = useConfigVariable(displayFGCSpec);

  // show fgc config variable
  let displayFGC =
    FGCConfigVar.status === UseConfigVariableStatus.SUCCESS &&
    FGCConfigVar.value &&
    FGCCampaign &&
    FGCCampaign.startDate &&
    new Date() >= new Date(FGCCampaign.startDate);

  // don't display for certain non profits
  if (nonprofitId && SPECIAL_NONPROFIT_IDS.includes(nonprofitId)) {
    displayFGC = false;
  }

  const FGCDepleted =
    FGCCampaign &&
    FGCCampaign.maxTotalMatchAmount - FGCCampaign.matchedTotal <
      FGCCampaign.maxPerUserMatchAmount;

  const fetch = useCallback(() => {
    async function getCampaign() {
      if (!displayFGC) {
        return undefined;
      }
      const res = await queryApi(getDonationMatchCampaignRouteSpec, {
        routeTokens: { identifier: FGC_CAMPAIGN_CODE },
        body: {},
        queryParams: {},
      });
      return res;
    }
    return getCampaign();
  }, [displayFGC]);

  const handleResponse = useCallback(
    (FGCCampaign?: MatchingCampaignResponse) => {
      if (FGCCampaign && Object.keys(FGCCampaign).length) {
        setFGCCampaign(FGCCampaign as NonEmptyMatchingCampaignResponse);
      }
    },
    []
  );

  const handleError = useCallback((e: Error) => {
    logger.error({
      message: "An error occurred fetching fgc campaign",
      error: e,
    });
  }, []);

  useAsyncEffect({
    asyncOperation: fetch,
    handleResponse,
    handleError,
  });

  return {
    displayFGC,
    FGCDepleted,
    FGCCampaign,
  };
}
