import * as t from "io-ts";

import { stringEnumCodec } from "../../codecs";

/**
 * Frontend config variables, sent down to the client.
 */
export enum ConfigVariableKey {
  GLOBAL_NONPROFIT_ANNOUNCEMENT = "GLOBAL_NONPROFIT_ANNOUNCEMENT",
  SCHEDULED_EMAIL_SEND_DISABLED = "SCHEDULED_EMAILS_SEND",
  ENABLE_ALTERNATIVE_PAYMENT_METHODS = "ENABLE_ALTERNATIVE_PAYMENT_METHODS",
  CAMPAIGN_PREVIEW_VIDEO_ENABLED = "CAMPAIGN_PREVIEW_VIDEO_ENABLED",
  BANK_ENABLED = "BANK_ENABLED",
  CARD_ENABLED = "CARD_ENABLED",
  PAYMENT_REQUEST_ENABLED = "PAYMENT_REQUEST_ENABLED",
  PAYPAL_ENABLED = "PAYPAL_ENABLED",
  DISPLAY_FALL_GIVING_CHALLENGE = "DISPLAY_FALL_GIVING_CHALLENGE",
  FALL_GIVING_SHARE_CHALLENGE_AMOUNT = "FALL_GIVING_SHARE_CHALLENGE_AMOUNT",
  DISPLAY_BLACK_WOMEN_LEAD = "DISPLAY_BLACK_WOMEN_LEAD",
  ENABLE_TIPPING = "ENABLE_TIPPING",
  DISABLE_PAYMENT_METHODS = "DISABLE_PAYMENT_METHODS",
  NUM_NONPROFITS = "NUM_NONPROFITS",
}

export enum BackendConfigVariableKey {
  SLACK_SEARCH_NOTIFICATION_DEBOUNCE_SECONDS = "SLACK_SEARCH_NOTIFICATION_DEBOUNCE_SECONDS",
  PAYPAL_NONPROFIT_METADATA_LAST_SYNCED = "PAYPAL_NONPROFIT_METADATA_LAST_SYNCED",
  BLOCKED_ROUTING_NUMBERS = "BLOCKED_ROUTING_NUMBERS",
  DISABLE_BING = "DISABLE_BING",
}

export const configVariableKeyCodec = stringEnumCodec({
  name: "ConfigVariableKey",
  enumObject: ConfigVariableKey,
});

export interface ConfigVariableSpec<ConfigValueCodec extends t.Any> {
  configKey: ConfigVariableKey | BackendConfigVariableKey;
  valueCodec: ConfigValueCodec;
}

function makeConfigVariableSpec<ConfigValueCodec extends t.Any>(
  configKey: ConfigVariableKey | BackendConfigVariableKey,
  valueCodec: ConfigValueCodec
): ConfigVariableSpec<ConfigValueCodec> {
  return { configKey, valueCodec };
}

/**
 * This config variable adds an announcement that will be displayed on all
 * nonprofit profiles and donation modals. It can be overridden by
 * nonprofit-specific announcements specified in the nonprofit's `announcement`
 * metadata property.
 */
export const globalNonprofitAnnouncementConfigVariableSpec =
  makeConfigVariableSpec(
    ConfigVariableKey.GLOBAL_NONPROFIT_ANNOUNCEMENT,
    t.string
  );
export const scheduledEmailSendVariableSpec = makeConfigVariableSpec(
  ConfigVariableKey.SCHEDULED_EMAIL_SEND_DISABLED,
  t.boolean
);

export const numberOfNonprofitsVariableSpec = makeConfigVariableSpec(
  ConfigVariableKey.NUM_NONPROFITS,
  t.number
);

/**
 * @deprecated
 */
export const enableAlternativePaymentMethodsSpec = makeConfigVariableSpec(
  ConfigVariableKey.ENABLE_ALTERNATIVE_PAYMENT_METHODS,
  t.boolean
);

export const campaignPreviewVideoVariableEnabledSpec = makeConfigVariableSpec(
  ConfigVariableKey.CAMPAIGN_PREVIEW_VIDEO_ENABLED,
  t.boolean
);

/**
 * @deprecated
 */
export const bankEnabledSpec = makeConfigVariableSpec(
  ConfigVariableKey.BANK_ENABLED,
  t.boolean
);

/**
 * @deprecated
 */
export const cardEnabledSpec = makeConfigVariableSpec(
  ConfigVariableKey.CARD_ENABLED,
  t.boolean
);

/**
 * @deprecated
 */
export const paymentRequestEnabledSpec = makeConfigVariableSpec(
  ConfigVariableKey.PAYMENT_REQUEST_ENABLED,
  t.boolean
);

export const enableTippingSpec = makeConfigVariableSpec(
  ConfigVariableKey.ENABLE_TIPPING,
  t.boolean
);

export const paypalVariableEnabledSpec = makeConfigVariableSpec(
  ConfigVariableKey.PAYPAL_ENABLED,
  t.boolean
);

export const slackSearchNotificationDebounceSecondsSpec =
  makeConfigVariableSpec(
    BackendConfigVariableKey.SLACK_SEARCH_NOTIFICATION_DEBOUNCE_SECONDS,
    t.number
  );

export const fallGivingShareChallengeAmountSpec = makeConfigVariableSpec(
  ConfigVariableKey.FALL_GIVING_SHARE_CHALLENGE_AMOUNT,
  t.number
);

// Codec for sending config variables over the network, use t.unknown because of the dynamic value types
export const configVariableCodec = t.type({
  configKey: configVariableKeyCodec,
  configValue: t.unknown,
});
export type ConfigVariableResponse = t.TypeOf<typeof configVariableCodec>;

export const displayFGCSpec = makeConfigVariableSpec(
  ConfigVariableKey.DISPLAY_FALL_GIVING_CHALLENGE,
  t.boolean
);

export const displayBWLSpec = makeConfigVariableSpec(
  ConfigVariableKey.DISPLAY_BLACK_WOMEN_LEAD,
  t.boolean
);

export const disablePaymentMethodsSpec = makeConfigVariableSpec(
  ConfigVariableKey.DISABLE_PAYMENT_METHODS,
  t.string
);

/**
 * Records the last time we synced PayPal nonprofit metadata.
 * We only request metadata for nonprofits that have been created or updated
 * since this date when we run the sync with paypal job
 */
export const paypalNonprofitMetadataLastSyncedSpec = makeConfigVariableSpec(
  BackendConfigVariableKey.PAYPAL_NONPROFIT_METADATA_LAST_SYNCED,
  t.string
);

/**
 * A comma-separated list of routing numbers that are blocked from being used.
 * This is used to rapidly block routing numbers that are known to be fraudulent.
 *
 * Related Github issue: https://github.com/everydotorg/every.org/issues/17043
 * Unfortunately Stripe Radar does not work with bank transfers, it only works
 * with card payments, so we need our own system to block bank transfers.
 */
export const blockedRoutingNumbersSpec = makeConfigVariableSpec(
  BackendConfigVariableKey.BLOCKED_ROUTING_NUMBERS,
  t.string
);

export const disableBingSpec = makeConfigVariableSpec(
  BackendConfigVariableKey.DISABLE_BING,
  t.boolean
);
