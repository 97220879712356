import { UUID } from "io-ts-types/UUID";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import { LONG_DESCRIPTION_MAX_LENGTH } from "@every.org/common/src/codecs/nonprofit";
import { SafeInt } from "@every.org/common/src/codecs/number";
import { Currency } from "@every.org/common/src/entity/types";
import { checkYoutubeVideoUrlForPatternMatch } from "@every.org/common/src/helpers/socialNetworks";
import {
  deleteFundraiserRouteSpec,
  hideFundraiserRouteSpec,
  postFundraiserRouteSpec,
  unhideFundraiserRouteSpec,
  updateFundraiserRouteSpec,
} from "@every.org/common/src/routes/fundraiser";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import FundraiserValidationError from "src/pages/Fundraiser/EditForm/FundraiserValidationError";
import { FundraiserMatchCampaignEdit } from "src/pages/Fundraiser/EditForm/MatchCampaignCard";
import {
  PostFundraiser,
  UpdateFundraiser,
} from "src/pages/Fundraiser/EditForm/types";
import { queryApi } from "src/utility/apiClient";
import { logger } from "src/utility/logger";
import { sanctionsWordsChecker } from "src/utility/sanctionsWordsChecker";

export const FUNDRAISER_TITLE_MAX_LENGTH = 60;
export const FUNDRAISER_TITLE_MIN_LENGTH = 2;

const defaultCreationBody = {
  currency: Currency.USD,
  active: true,
  hidden: false,
  pinned: false,
  parentFundraiserId: null,
  startDate: null,
  endDate: null,
  goal: null,
  raisedOffline: null,
};

export function createValidation(params: {
  title?: string;
  description?: string | null;
  coverYoutubeVideoUrl?: string | null;
  matchCampaign?: FundraiserMatchCampaignEdit;
  maxTotalMatchAmount?: SafeInt | undefined;
  maxMatchPerDonationOneTimeAmount?: SafeInt | undefined;
  dateRange?: [Date | null, Date | null] | null[] | Date[];
}) {
  return {
    title: !params.title?.length
      ? {
          success: false,
          message: "Title cannot be empty",
        }
      : params.title.length > FUNDRAISER_TITLE_MAX_LENGTH
      ? {
          success: false,
          message: `Max title length is ${FUNDRAISER_TITLE_MAX_LENGTH}`,
        }
      : params.title.length < FUNDRAISER_TITLE_MIN_LENGTH
      ? {
          success: false,
          message: `Min title length is ${FUNDRAISER_TITLE_MIN_LENGTH}`,
        }
      : undefined,
    description: !params.description?.length
      ? {
          success: false,
          message: "Description cannot be empty",
        }
      : params.description.length > LONG_DESCRIPTION_MAX_LENGTH
      ? {
          success: false,
          message: `Max description length is ${LONG_DESCRIPTION_MAX_LENGTH}`,
        }
      : undefined,
    coverYoutubeVideoUrl:
      params.coverYoutubeVideoUrl &&
      !checkYoutubeVideoUrlForPatternMatch(params.coverYoutubeVideoUrl)
        ? {
            success: false,
            message: "Youtube video url is invalid",
          }
        : undefined,
  };
}

function sanctionsWordsCheckerHandler(
  title?: string,
  description?: string | null,
  donationThankYouMessage?: string,
  nonprofit?: ContextNonprofit,
  userId?: string
) {
  const isTitle = sanctionsWordsChecker(
    title ? title : "",
    "title",
    nonprofit,
    userId
  );
  const isDescription = sanctionsWordsChecker(
    description ? description : "",
    "description",
    nonprofit,
    userId
  );
  const isDonationThankYou = sanctionsWordsChecker(
    donationThankYouMessage ? donationThankYouMessage : "",
    "donationThankYouMessage",
    nonprofit,
    userId
  );
  if (isTitle || isDescription || isDonationThankYou) {
    throw new FundraiserValidationError({
      message:
        "Sorry, our system has detected there is a chance this fundraiser would violate US sanctions. Please contact us.",
      validation: createValidation({ title, description }),
    });
  }
}

export async function postFundraiser(
  nonprofitId: UUID,
  { title, description, metadata, ...rest }: PostFundraiser,
  nonprofit?: ContextNonprofit,
  userId?: string
): Promise<FundraiserResponse> {
  if (
    !title.length ||
    (title?.length || 0) > FUNDRAISER_TITLE_MAX_LENGTH ||
    (title?.length || 0) < FUNDRAISER_TITLE_MIN_LENGTH ||
    !description.length ||
    description.length > LONG_DESCRIPTION_MAX_LENGTH
  ) {
    throw new FundraiserValidationError({
      message: "Please fix the errors above and resubmit",
      validation: createValidation({ title, description }),
    });
  }

  sanctionsWordsCheckerHandler(
    title,
    description,
    metadata?.donationThankYouMessage,
    nonprofit,
    userId
  );

  const { fundraiser } = await queryApi(postFundraiserRouteSpec, {
    routeTokens: {},
    queryParams: {},
    body: {
      ...defaultCreationBody,
      title,
      description,
      nonprofitId,
      metadata,
      ...rest,
    },
  });

  return fundraiser;
}

export async function updateFundraiser(
  fundraiserId: UUID,
  nonprofitId: UUID,
  { title, description, metadata, ...rest }: UpdateFundraiser,
  nonprofit?: ContextNonprofit,
  userId?: string
): Promise<FundraiserResponse> {
  if (
    (typeof title === "string" && !title.length) ||
    (title?.length || 0) > FUNDRAISER_TITLE_MAX_LENGTH ||
    (title?.length || 0) < FUNDRAISER_TITLE_MIN_LENGTH ||
    (typeof description === "string" && !description.length) ||
    (description?.length || 0) > LONG_DESCRIPTION_MAX_LENGTH ||
    (metadata?.coverYoutubeVideoUrl &&
      !checkYoutubeVideoUrlForPatternMatch(metadata?.coverYoutubeVideoUrl))
  ) {
    throw new FundraiserValidationError({
      message: "Please fix the errors above and resubmit",
      validation: createValidation({
        title,
        description,
        coverYoutubeVideoUrl: metadata?.coverYoutubeVideoUrl,
      }),
    });
  }

  sanctionsWordsCheckerHandler(
    title,
    description,
    metadata?.donationThankYouMessage,
    nonprofit,
    userId
  );

  const body = {
    title,
    description,
    metadata,
    ...rest,
  };

  const response = await queryApi(updateFundraiserRouteSpec, {
    routeTokens: {
      fundraiserIdentifier: fundraiserId,
      nonprofitIdentifier: nonprofitId,
    },
    queryParams: {},
    body,
  });

  return response.fundraiser;
}

export async function deleteFundraiser(fundraiserId: UUID, nonprofitId: UUID) {
  try {
    await queryApi(deleteFundraiserRouteSpec, {
      routeTokens: {
        fundraiserIdentifier: fundraiserId,
        nonprofitIdentifier: nonprofitId,
      },
      queryParams: {},
      body: {},
    });
  } catch (error) {
    logger.error({
      error,
      message: "Failed to delete Fundraiser",
      data: { fundraiserId },
    });
  }
  return;
}

export async function hideFundraiser(fundraiserId: UUID, nonprofitId: UUID) {
  try {
    return await queryApi(hideFundraiserRouteSpec, {
      routeTokens: {
        fundraiserIdentifier: fundraiserId,
        nonprofitIdentifier: nonprofitId,
      },
      queryParams: {},
      body: {},
    });
  } catch (error) {
    logger.error({
      error,
      message: "Failed to hide Fundraiser",
      data: { fundraiserId },
    });
  }
  return;
}

export async function unhideFundraiser(fundraiserId: UUID, nonprofitId: UUID) {
  try {
    return await queryApi(unhideFundraiserRouteSpec, {
      routeTokens: {
        fundraiserIdentifier: fundraiserId,
        nonprofitIdentifier: nonprofitId,
      },
      queryParams: {},
      body: {},
    });
  } catch (error) {
    logger.error({
      error,
      message: "Failed to unhide Fundraiser",
      data: { fundraiserId },
    });
  }
  return;
}
